import IntlMessageFormat from "intl-messageformat";
import { getActiveLocaleValueByCookie } from "@modules/localedDomain/getActiveLocaleValueByCookie";

import { DEFAULT_LOCALE } from "./localedDomain/defaultLocale";

export const getCurrentLocale = () => {
  return getActiveLocaleValueByCookie() || DEFAULT_LOCALE;
};

export const stringFormatter = (message: string) =>
  new IntlMessageFormat(message, getCurrentLocale()).format;
