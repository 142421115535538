import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getConf } from "@retail/config";
import { SELECTED_LOCALE_COOKIE } from "@modules/localedDomain/selectedLocaleCookie";

import { getLocale } from "./utils";
import { customPathDetector } from "./customPathDetector";
import { keyMapperPostProcessor } from "./keyMapperPostProcessor";
import { languages } from "./languages";

const TRANSLATIONS_URL = getConf("TRANSLATIONS_URL");

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customPathDetector);

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  // It will load resources from S3 Bucket using the XMLHttpRequest or the fetch API.
  .use(HttpApi)
  .use(languageDetector)
  .use(keyMapperPostProcessor)
  .init({
    postProcess: "keyMapperPostProcessor",
    fallbackLng: languages.en,
    load: "currentOnly",
    interpolation: {
      prefix: "{",
      suffix: "}",
      escapeValue: false,
    },
    lowerCaseLng: true,
    backend: {
      crossDomain: true,
      // URL to translation files (S3 Bucket)
      loadPath: (languages: string[]) =>
        // should return falsy value when executed in test env (by jest) to prevent http requests during tests
        global?.process?.env?.NODE_ENV === "test"
          ? ""
          : `${TRANSLATIONS_URL}${getLocale(languages[0]!)}.json`,
    },
    detection: {
      order: ["customPathDetector", "cookie"],
      lookupFromPathIndex: 0,
      lookupCookie: SELECTED_LOCALE_COOKIE,
      caches: [],
    },
    react: {
      useSuspense: false,
    },
  });

export { i18n };
