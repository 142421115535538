import "./setup";
import { i18n } from "@i18n/i18next";
import { isErrorLoggingEnabled } from "@modules/isErrorLoggingEnabled";
import {
  getActiveLocaleValueByCookie,
  setLocaleInCookie,
} from "@modules/localedDomain/getActiveLocaleValueByCookie";
import { getLocaleByDomain } from "@modules/localedDomain/helpers";
import Reporter, { ErrorTypes } from "@retail/reporter";
import { getOr } from "lodash/fp";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

function Root() {
  const As = lazy(() => import("./Root"));

  return (
    <Suspense fallback="">
      <As />
    </Suspense>
  );
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err) {
    if (isErrorLoggingEnabled()) {
      Reporter.reportError(ErrorTypes.react, {
        ...err,
        name: err.name || "MFE Error",
        message: err.message || "Unknown error in MFE",
        stack: err.stack,
        caughtAt: "singleSpaReact's errorBoundary method",
        url: window.location.href,
      } as Error);
    } else {
      console.log("error", getOr(err, ["stack"], err)); // eslint-disable-line no-console
    }
    return null as unknown as React.ReactElement;
  },
});

const CLM_GLOBAL_STYLES_CLASSNAME = "clm-global-styles";

export const mount = (...args: any[]) => {
  Array.from(
    document.querySelectorAll(`.${CLM_GLOBAL_STYLES_CLASSNAME}`),
  ).forEach((globalStylesElement) =>
    globalStylesElement.removeAttribute("media"),
  );
  const domain = window.location.pathname.split("/").filter(Boolean)[0];
  const localeByDomain = getLocaleByDomain(domain!);

  // Align locale in cookie with locale from url. They can be different e.g. after user switching language from langA to langB
  // and using back arrow - url will contain langA but cookie with have langB. url is source of truth
  if (localeByDomain && localeByDomain !== getActiveLocaleValueByCookie()) {
    setLocaleInCookie(localeByDomain);
  }

  // handles case when at this point of time i18next is initialized (which happens at the same time with translations being loaded)
  // so at this point we have all translations and it's safe to mount MFE
  if (i18n.isInitialized) {
    // @ts-ignore
    return lifecycles.mount(...args);
  }

  // set event listeners for successful and unsuccessful translations fetch, mount MFE on either event
  i18n.on("loaded", () => {
    // @ts-ignore
    lifecycles.mount(...args);
  });
  i18n.on("failedLoading", () => {
    // @ts-ignore
    lifecycles.mount(...args);
  });

  // return promise in such case according single-spa's requirements
  return Promise.resolve();
};

export const unmount = (...args: any[]) => {
  Array.from(
    document.querySelectorAll(`.${CLM_GLOBAL_STYLES_CLASSNAME}`),
  ).forEach((globalStylesElement) =>
    globalStylesElement.setAttribute("media", "max-width: 1px"),
  );

  // @ts-ignore
  return lifecycles.unmount(...args);
};
