import { getCurrentLocale } from "@modules/i18nHelpers";
import { AVAILABLE_LOCALES } from "@retail/backoffice-clm-urls";
import { get } from "lodash/fp";

export const ROUTE_PREFIX = `/clm`;
export const EMAIL_TEMPLATES_PATH = "/email-templates/";

const localesByDomain = AVAILABLE_LOCALES.reduce(
  (accumulator, { alias, value }) => ({ ...accumulator, [alias]: value }),
  {},
);
const domainsByLocale = AVAILABLE_LOCALES.reduce(
  (accumulator, { alias, value }) => ({ ...accumulator, [value]: alias }),
  {},
);

const getDomainByLocale = (locale: string) => get([locale], domainsByLocale);
export const getLocaleByDomain = (localeDomain: string) =>
  get([localeDomain], localesByDomain);

export const getLocaledDomain = () => getDomainByLocale(getCurrentLocale());
export const API_PREFIX = `/${getLocaledDomain()}${ROUTE_PREFIX}`;
