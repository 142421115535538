export const customPathDetector = {
  name: "customPathDetector",
  lookup(options: { lookupFromPathIndex: number }) {
    let found;
    if (typeof window !== "undefined") {
      const language = window.location.pathname.match(/\/([a-zA-Z-_]*)/g);
      if (language instanceof Array) {
        if (typeof options.lookupFromPathIndex === "number") {
          if (typeof language[options.lookupFromPathIndex] !== "string") {
            return undefined;
          }
          found = language[options.lookupFromPathIndex]!.replace("/", "");
        } else {
          found = language[0]!.replace("/", "");
        }
      }
    }
    return found;
  },
};
