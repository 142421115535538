// Should have doubled keys (en and en-gb) because we detect lang both from cookie (where value
// is stored in format "en-GB") and from path (where value is in format "en", except "de_AT" and
// "sv_SE").
// Keys are lower case because i18next passes lower cased language to backend's loadPath options.
export const languages: Record<string, string> = Object.freeze({
  en: "en",
  "en-gb": "en",
  de: "de-DE",
  "de-de": "de-DE",
  es: "es-ES",
  "es-se": "es-ES",
  fr: "fr-FR",
  "fr-fe": "fr-FR",
  "fr-be": "fr-BE",
  it: "it-IT",
  "it-it": "it-IT",
  nl: "nl-NL",
  "nl-nl": "nl-NL",
  "nl-be": "nl-BE",
  pl: "pl-PL",
  "pl-pl": "pl-PL",
  de_at: "de-AT",
  sv_se: "sv-SE",
});
