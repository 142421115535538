import { PostProcessorModule } from "i18next";

import { TRANSLATION_KEYS_MAP } from "./translationKeysMap";

export const keyMapperPostProcessor: PostProcessorModule = {
  type: "postProcessor",
  name: "keyMapperPostProcessor",
  process: function (value, keyArray, options, translator) {
    const [key] = keyArray;

    if (key! in TRANSLATION_KEYS_MAP) {
      return translator.translate([TRANSLATION_KEYS_MAP[key!]], options);
    }

    return value;
  },
};
