export const TRANSLATION_KEYS_MAP: Record<string, string> = {
  customer_lead_is_archived: "errors.customer_lead_is_archived",
  upstream_validation_failed: "opportunity_car_stock_number_not_available",
  "fieldOptions.oppReopenReason.SUPERVISOR_STATUS_OVERWRITE":
    "fieldOptions.oppReopenReason.supervisorStatusOverwrite",
  "fieldOptions.oppReopenReason.AUTOMATIC_CAR_DOWNPRICING":
    "fieldOptions.oppReopenReason.automaticCarDownpricing",
  "customerLead.archivingReason.TEST_DATA":
    "customerLead.archivingReason.testData",
  "customerLead.archivingReason.NOT_REACHABLE":
    "customerLead.archivingReason.notReachable",
  "customerLead.archivingReason.DUPLICATE":
    "customerLead.archivingReason.duplicate",
  "customerLead.archivingReason.MERGED_DUPLICATES":
    "customerLead.archivingReason.mergedDuplicates",
  "customerLead.archivingReason.OTHER": "customerLead.archivingReason.other",
  "customerLead.archivingReason.Other": "customerLead.archivingReason.other",
  "clickToCall.initialize_call_customer_lead_archived":
    "initialize_call_customer_lead_archived",
  "clickToCall.initialize_call_contact_phone_not_found":
    "initialize_call_contact_phone_not_found",
  "customerLead.archivingReason.SPAM": `customerLead.archivingReason.spam`,
  lead_to_merge_not_found: "customerRoute.mergingForm.error.leadNotFound",
  customer_lead_order_same_car: "customerRoute.mergingForm.error.sameCarOrder",
  different_agents: "customerRoute.mergingForm.error.differentAgents",
  mergeable_customer_lead_is_archived:
    "customerRoute.mergingForm.error.archivedLead",
  order_status_invalid: "errors.order_status_invalid",
  TRADEIN_IN_PROGRESS: "fieldOptions.tradeInStatus.inProgress",
  APPRAISAL_READY: "fieldOptions.tradeInStatus.ready",
  EVALUATION_IN_PROGRESS: "fieldOptions.tradeInStatus.incomplete",
  PENDING_APPRAISAL: "fieldOptions.tradeInStatus.pendingAppraisal",
  SOLD_AND_HANDED_OVER: "fieldOptions.tradeInStatus.soldAndHandedOver",
  TRADEIN_COMPLETED: "fieldOptions.tradeInStatus.complete",
  APPRAISAL_EXPIRED: "fieldOptions.tradeInStatus.appraisalExpired",
  TRADEIN_NOT_POSSIBLE: "fieldOptions.tradeInStatus.notPossible",
  fr_BE: "customerRoute.customer.frBE",
  nl_BE: "customerRoute.customer.nlBE",
  "customerRoute.oppItem.publishingBlockerReason.eQA":
    "customerRoute.oppItem.publishingBlockerReason.EQA",
  "customerRoute.oppItem.publishingBlockerSubreason.retakeBeautyShotsDueToLowQuality":
    "customerRoute.oppItem.publishingBlockerSubreason.retakeShots",
  "customerRoute.oppItem.publishingBlockerSubreason.retakeImperfectionImagesDueToLowQuality":
    "customerRoute.oppItem.publishingBlockerSubreason.retakeImages",
  "customerRoute.oppItem.publishingBlockerSubreason.reentryCheck":
    "customerRoute.oppItem.publishingBlockerSubreason.reEntryCheck",
  "customerRoute.oppItem.publishingBlockerSubreason.returnToA1ByClaim":
    "customerRoute.oppItem.publishingBlockerSubreason.returnByClaim",
  "customerRoute.oppItem.publishingBlockerSubreason.clarifyDocumentLocationShippingStatus":
    "customerRoute.oppItem.publishingBlockerSubreason.clarifyDocLocationStatus",
  "customerRoute.oppItem.publishingBlockerSubreason.documentDuplication":
    "customerRoute.oppItem.publishingBlockerSubreason.docDuplication",
  "customerRoute.oppItem.publishingBlockerSubreason.updateCarDescription":
    "customerRoute.oppItem.publishingBlockerSubreason.updateDescription",
  "customerRoute.oppItem.publishingBlockerSubreason.clarifyCarLocationTransportStatus":
    "customerRoute.oppItem.publishingBlockerSubreason.clarifyLocation",
  "customerRoute.oppItem.publishingBlockerSubreason.solveOngoingLegalCase":
    "customerRoute.oppItem.publishingBlockerSubreason.legalCase",
  /**/
  "fieldOptions.oppCloseReason.PRICE": "fieldOptions.oppCloseReason.price",
  "fieldOptions.oppCloseReason.NO_RESPONSE":
    "fieldOptions.oppCloseReason.customerDoesntRespond",
  "fieldOptions.oppCloseReason.noResponse":
    "fieldOptions.oppCloseReason.customerDoesntRespond",
  "fieldOptions.oppCloseReason.DUPLICATE":
    "fieldOptions.oppCloseReason.duplicate",
  "fieldOptions.oppCloseReason.DELIVERY_TIME":
    "fieldOptions.oppCloseReason.deliveryTime",
  "fieldOptions.oppCloseReason.OTHER_REASON":
    "fieldOptions.oppCloseReason.otherReason",
  "fieldOptions.oppCloseReason.ONLINE_CONCEPT":
    "fieldOptions.oppCloseReason.onlineConcept",
  "fieldOptions.oppCloseReason.VAT_NOT_DEDUCTIBLE":
    "fieldOptions.oppCloseReason.vatNotDeductible",
  "fieldOptions.oppCloseReason.THE_CAR_IS_NOT_AVAILABLE_ANYMORE":
    "fieldOptions.oppCloseReason.carIsNotAvailable",
  "fieldOptions.oppCloseReason.FINANCING_DECLINED":
    "fieldOptions.oppCloseReason.financingDeclined",
  "fieldOptions.oppCloseReason.SELL_AGENT_WAS_TOO_PUSHY":
    "fieldOptions.oppCloseReason.sellAgentTooPushy",
  "fieldOptions.oppCloseReason.LOCATION_DISCREPANCY":
    "fieldOptions.oppCloseReason.locationDiscrepancy",
  "fieldOptions.oppCloseReason.BUYER_FOUND_ANOTHER_CAR":
    "fieldOptions.oppCloseReason.buyerFoundAnotherCar",
  "fieldOptions.oppCloseReason.BAD_WKDA_REPUTATION":
    "fieldOptions.oppCloseReason.badWkdaReputation",
  "fieldOptions.oppCloseReason.CONTRACT_WITHDRAWN":
    "fieldOptions.oppCloseReason.contractWithdrawn",
  "fieldOptions.oppCloseReason.CAR_CHARACTERISTICS":
    "fieldOptions.oppCloseReason.carCharacteristics",
  "fieldOptions.oppCloseReason.TEST_DRIVE_AVAILABILITY":
    "fieldOptions.oppCloseReason.testDriveAvailability",
  "fieldOptions.oppCloseReason.CUSTOMER_NOT_INTERESTED_ANYMORE":
    "fieldOptions.oppCloseReason.customerNotInterestedAnymore",
  "fieldOptions.oppCloseReason.NO_CONTACT_DETAILS":
    "fieldOptions.oppCloseReason.noContactDetails",
  "fieldOptions.oppCloseReason.CAR_IS_NOT_AVAILABLE_ANYMORE":
    "fieldOptions.oppCloseReason.carIsNotAvailableAnymore",
  "fieldOptions.oppCloseReason.ORDER_WAS_CANCELLED":
    "fieldOptions.oppCloseReason.orderWasCancelled",
  "fieldOptions.oppCloseReason.theCarIsNotAvailableAnymore":
    "fieldOptions.oppCloseReason.carIsNotAvailableAnymore",
  "fieldOptions.oppCloseReason.LEAD_WAS_ARCHIVED":
    "fieldOptions.oppCloseReason.leadWasArchived",
  "fieldOptions.oppCloseReason.WRONG_CONTACT_DETAILS":
    "fieldOptions.oppCloseReason.wrongContactDetails",
  "fieldOptions.oppCloseReason.WE_CANNOT_SELL_TO_THIS_CUSTOMER":
    "fieldOptions.oppCloseReason.weCannotSellToThisCustomer",
  "fieldOptions.oppCloseReason.NOT_PLANNING_TO_BUY":
    "fieldOptions.oppCloseReason.notPlanningToBuy",
  "fieldOptions.oppCloseSubReason.FINANCING_CONDITIONS_NOT_ATTRACTIVE":
    "fieldOptions.oppCloseSubReason.financingConditionsNotAttractive",
  "fieldOptions.oppCloseReason.LOW_SCORE_LEAD":
    "fieldOptions.oppCloseReason.lowScoreLead",
  "fieldOptions.oppCloseReason.NOT_AVAILABLE_FOR_ASSIGNMENT":
    "fieldOptions.oppCloseReason.notAvailableForAssignment",
};
